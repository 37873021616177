import "./Home.css";
import { StopwatchFill, FileEarmarkPersonFill } from 'react-bootstrap-icons';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Home() {
  return (
    <div className="Home">
      <div id="features">
        <h1><StopwatchFill size={20} className="mb-2 mr-1 Home-header-icon" />Features</h1>
        <p  className="text-justify">Track your warmup/set/rest time, high-intensity interval training (HIIT), tabata, Time Under Tension, and other interval trainings as well as workout history:</p>
        <ul>
          <li>Custom warmup
          </li>
          <li>Custom set and rest duration (or no rest) for each exercise with duration goal
          </li>
          <li>Custom number of workout sets
          </li>
          <li>
            Next button to move to the next exercise if you finish the current one early or hit your duration goal
          </li>
          <li>
            Quantity goal and actual quantity for exercise
          </li>
          <li>
            Announce current time/set so you don't have to look at the screen all the time
          </li>
          <li>
            Circuit vs station exercise order
          </li>
          <li>
            Save multiple workouts for easy switching between them
          </li>
          <li>
            Notes for warm-up, individual exercises, warm-up histories and exercise histories
          </li>
          <li>
            Screen timeout disable
          </li>
          <li>
            Different notification sounds for set and rest time
          </li>
          <li>
            Workout and exercise history
          </li>
          <li>
            Workout and exercise progress tracking
          </li>
          <li>
            Silent mode settings: vibration only without notification sound
          </li>
          <li>
            Sync, backup, and restore your workout, exercise, and histories across devices or app re-installation
          </li>
          <li>
            Can run independently on Apple Watch
          </li>
        </ul>
        <p>When you purchase the app, you can also:</p>
        <ul>
          <li>Connect to Strava to have workout histories uploaded automatically</li>
          <li>Play Spotify music directly from within the app</li>
          <li>No ads</li>
        </ul>
        <p>In order to run continuously on Apple Watch, FitMate needs permission to create Workout through Health app. Apple Watch needs to be connected to iPhone from time to time to sync</p>
      <Row>
        <Col sm className="my-2 text-sm-right text-center">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.kenzensoft.fitmate" rel="noopener noreferrer" className="align-self-center">
            <img src="/google-play-badge.png" height="58" alt="" />
          </a>
        </Col>
        <Col sm className="my-2 text-sm-left text-center">
          <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/us/app/fitmate-interval-stopwatch/id975433024?mt=8">
            <img src="/AppleAppStoreBadge.png" height="58" alt="" />
          </a>
        </Col>
      </Row>
        <p>&nbsp;</p>
      </div>
      <div id="about-me">
        <h1><FileEarmarkPersonFill size={20} className="mb-2 mr-1 Home-header-icon" />About me</h1>
        <p className="text-justify">Hello, my name is Hung Ho. I am a software developer, cooking enthusiast, bodyweight homegym-goer. My driving passion is to combine my interestings and skills to help all of us work out better. I work on this app during my spare time and completely self-funded.</p>
        <p className="text-justify">Thank you very much for supporting my app. And if you have any question or feedback, just drop me an email at <a href="mailto:connect@kenzensoft.com" rel="noreferrer" target="_blank">connect@kenzensoft.com</a>.</p>
      </div>
    </div>
  );
}

export default Home;