function Privacy() {
  return (
    <div className="">
      <h1>Privacy Policy</h1>
      <p>This policy applies to all information collected or this website and other apps for all devices and platforms.</p>
      <hr />
      <h2>Information we collect</h2>
      <p>Accounts are created anonymously using email addresses and passwords.</p>
      <p>Email addresses are only used for logging in, password resets, responding to emails that you initiate, sending notifications that you request, and communicating new features and bug fixes. We don’t send recurring promotional emails or newsletters.</p>
      <hr />
      <h2>Technical Basics</h2>
      <p>If you enable notifications, we must store a token to send them. We never use notifications for marketing.</p>
      <p>We use cookies on the site and similar tokens in the app to keep you logged in and enable seamless experience.</p>
      <p>Our server software may also store basic technical information, such as your IP address, in temporary logs.</p>
      <hr/>
      <h2>Analytics</h2>
      <p>Our service collects aggregate, anonymous statistics, such as the percentage of users who use particular features, to improve the service.</p>
      <hr/>
      <h2>Information usage</h2>
      <p>We use the information we collect to operate and improve our services, and customer support.</p>
      <p>We do not share personal information with outside parties except to the extent necessary to accomplish our service’s functionality. We may share anonymous, aggregate statistics with outside parties.</p>
      <p>We may disclose your information in response to subpoenas, court orders, or other legal requirements; to exercise our legal rights or defend against legal claims; to investigate, prevent, or take action regarding illegal activities, suspected fraud or abuse, violations of our policies; or to protect our rights and property.</p>
      <p>In the future, we may sell to, buy, merge with, or partner with other businesses. In such transactions, user information may be among the transferred assets.</p>
      <hr/>
      <h2>Security</h2>
      <p>We implement a variety of industry-standard security measures to help keep your information secure. For instance, all communication with the service requires HTTPS. Passwords are hashed, not stored, using industry-standard methods.</p>
      <hr/>
      <h2>Accessing, changing, or deleting information</h2>
      <p>We may delete your information at any time and for any reason, such as technical needs, legal concerns, abuse prevention, removal of idle accounts, data loss, or any other reason.</p>
      <hr/>
      <h2>Third-party links and content</h2>
      <p>We may display links and content from third-party sites. These have their own independent privacy policies, and we have no responsibility or liability for their content or activities.</p>
      <hr/>
      <h2>California Online Privacy Protection Act Compliance</h2>
      <p>We comply with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent.</p>
      <hr/>
      <h2>Children’s Online Privacy Protection Act Compliance</h2>
      <p>We never collect or maintain information at our website from those we actually know are under 13, and no part of our website is structured to attract anyone under 13.</p>
      <hr/>
      <h2>Information for European Union Customers</h2>
      <p>By using our service and providing your information, you authorize us to collect, use, and store your information outside of the European Union.</p>
      <hr/>
      <h2>International Transfers of Information</h2>
      <p>Information may be processed, stored, and used outside of the country in which you are located. Data privacy laws vary across jurisdictions, and different laws may be applicable to your data depending on where it is processed, stored, or used.</p>
      <hr/>
      <h2>Your Consent</h2>
      <p>By using our site or apps, you consent to our privacy policy.</p>
      <hr/>
      <h2>Contacting Us</h2>
      <p>If you have questions regarding this privacy policy, you may email <a href="mailto:connect@kenzensoft.com" rel="noreferrer" target="_blank">connect@kenzensoft.com</a>.</p>
      <hr/>
      <h2>Changes to this policy</h2>
      <p>If we decide to change our privacy policy, we will post those changes on this page. Summary of changes so far:</p>
      <ul>
        <li><em>December 21<sup>st</sup>, 2020</em>: First publication.</li>
      </ul>
      <p>&nbsp;</p>
    </div>
  );
}

export default Privacy;