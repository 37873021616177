import React from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import "./App.css";
import "./sticky-footer-navbar.css";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";
import { HashLink } from "react-router-hash-link";

function App() {
  const year = (new Date()).getFullYear();
  return (
    <Router>
      <Navbar collapseOnSelect bg="light" expand="md" className="mb-3" variant="light">
        <LinkContainer to="/">
          <Navbar.Brand className="font-weight-bold text-dark">
            <img src={logo} width="30" height="30" className="d-inline-block align-top mr-2" alt="logo" loading="lazy" />
            FitMate HIIT Stopwatch
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <HashLink to="/#features">
            <Nav.Link className="text-dark">Features</Nav.Link>
          </HashLink>
          <HashLink to="/#about-me">
            <Nav.Link className="text-dark">About Me</Nav.Link>
          </HashLink>
        </Navbar.Collapse>
      </Navbar>
      <div className="App container">
        <Routes />
      </div>
      <div>
        <p>&nbsp;</p>
      </div>
      <footer className="footer">
        <div className="container d-flex mt-2">
          <small className="align-middle text-muted" style={{ lineHeight: "44px" }}>&copy;{year} KenZenSoft Inc.</small>
          <LinkContainer to="/privacy">
            <Button variant="link" className="text-dark App-no-underline ml-auto">Privacy</Button>
          </LinkContainer>
          <LinkContainer to="/terms">
            <Button variant="link" className="text-dark App-no-underline">Terms</Button>
          </LinkContainer>
        </div>
      </footer>
    </Router>
  );
}

export default App;
